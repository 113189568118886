<script setup lang="ts">
import { tv } from "tailwind-variants"

import { isActive } from "~/utils/routing"
import { tvConfig } from "~/utils/tailwindVariantsConfig"

const RouterLink = resolveComponent("RouterLink")

defineProps<{
  icon: string
  to?: string
  exact?: boolean
  disabled?: boolean
  danger?: boolean
}>()

defineEmits<(event: "click") => void>()

const classes = tv(
  {
    slots: {
      link: "group flex w-full items-center gap-2 rounded px-2 py-2 text-dusk-600 transition-colors hover:bg-dusk-100 relative select-none",
      icon: "text-dusk-300 transition-colors group-hover:text-dusk-600 text-[24px]",
    },
    variants: {
      disabled: {
        true: {
          link: "cursor-not-allowed opacity-60",
        },
      },
      danger: {
        true: {
          link: "text-red-600",
          icon: "text-red-600 group-hover:text-red-600",
        },
      },
      active: {
        true: {
          icon: "text-forest-700",
          link: "text-forest-700 bg-forest-200 bg-opacity-25 hover:bg-forest-500 hover:bg-opacity-25",
        },
      },
    },
  },
  tvConfig,
)
</script>

<template>
  <RouterLink v-slot="{ href, isExactActive, navigate }" custom :to="to || ''">
    <component
      :is="to ? 'a' : 'button'"
      :href="!disabled ? (to?.startsWith('http') ? to : href) : undefined"
      :target="to?.startsWith('http') ? '_blank' : '_self'"
      :class="
        classes({
          active: exact
            ? isExactActive
            : isActive(to ?? 'undefined', $router.currentRoute.value.path),
          disabled,
          danger,
        }).link()
      "
      @click="
        to
          ? !to.startsWith('http') && !disabled && navigate($event)
          : $emit('click')
      ">
      <div
        v-if="
          exact
            ? isExactActive
            : isActive(to ?? 'undefined', $router.currentRoute.value.path)
        "
        class="absolute -left-2 h-5 w-1 rounded-full bg-forest-700"></div>
      <Icon
        :name="icon"
        :class="
          classes({
            active: exact
              ? isExactActive
              : isActive(to ?? 'undefined', $router.currentRoute.value.path),
            disabled,
            danger,
          }).icon()
        " />
      <slot />
    </component>
  </RouterLink>
</template>
